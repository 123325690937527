<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav :active-index="3" />
      <service-and-support-list
        v-if="pid != ''"
        @change="search_change"
        :active-id="pid"
      />
      <div class="mainView">
        <div class="leftView">
          <div class="item" v-for="(item, index) in tabList" :key="index">
            <span
              :class="subId == item.id ? 'active' : ''"
              @click="tabClick(item.id)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="rightView">
          <section class="videoWrap" v-if="video.videoUrl != ''">
            <div class="videoBox" @click="videoStart">
              <img class="img" :src="video.pic" alt="" />
              <img class="btn" src="../assets/images/videoBtn.png" alt="" />
            </div>
          </section>
          <section v-html="html" class="html-view"></section>
        </div>
      </div>
    </div>
    <video-dialog
      :show="videoDialogShow"
      :data="videoDialogData"
      @close="videoDialogShow = false"
    />
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import ServiceAndSupportList from "@/components/service-and-support-list";
import videoDialog from "@/components/video-dialog";
export default {
  name: "KnowledgeBaseList",
  components: {
    ServiceAndSupportNav,
    ServiceAndSupportList,
    videoDialog,
  },
  data() {
    return {
      id: this.$route.query.id,
      pid: this.$route.query.pid,
      tabList: [],

      video: {},
      videoDialogShow: false,
      videoDialogData: {},

      html: "",

      f_cate_id: "", //主分类
      subId: this.$route.query.twoId || "", //分类
    };
  },
  watch: {
    $route(to, from) {
      this.subId = to.query.twoId;
    },
  },
  mounted() {
    this.knowledgeBaseListTabFun();
    this.getData();
  },
  methods: {
    /**
     * 搜索回调
     */
    search_change(e) {
      if (e != "") {
        this.$router.push(`/knowledge_base_list?keyword=${e}`);
      }
    },
    tabClick(e) {
      this.$router.push(`/knowledge_base_list?id=${this.pid}&subId=${e}`);
    },
    videoStart() {
      this.videoDialogData = this.video;
      this.videoDialogShow = true;
    },
    knowledgeBaseListTabFun() {
      this.$http.knowledgeCate({ parent_id: this.pid }).then((res) => {
        if (res.code == 200) {
          this.tabList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**获取数据 */
    getData() {
      this.$http.knowledgeBaseUpDetails({ id: this.id }).then((res) => {
        if (res.code == 200) {
          // this.f_cate_id = res.data.f_cate_id;
          // this.subId = res.data.cate_id;
          this.html = res.data.content;
          this.video = {
            pic: res.data.pic,
            videoUrl: res.data.video,
          };
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  .mainView {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 100px;
    border-top: 1px solid #e6e6e6;
    @include respond-to(md) {
      padding: 10px 0 20px;
    }
    .leftView {
      width: 248px;
      @include respond-to(lg) {
        width: 150px;
      }
      @include respond-to(md) {
        width: 120px;
      }
      .item {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 2;
        @include respond-to(sm) {
          font-size: 12px;
        }
        span {
          cursor: pointer;
          &:hover {
            color: #0075c1;
          }
          &.active {
            color: #0075c1;
            pointer-events: none;
          }
        }
      }
    }
    .rightView {
      width: calc(100% - 248px);
      @include respond-to(lg) {
        width: calc(100% - 150px);
      }
      @include respond-to(md) {
        width: calc(100% - 120px);
      }
    }
  }
}
.videoWrap {
  padding: 60px;
  margin-bottom: 30px;
  @include respond-to(md) {
    padding: 10px;
    margin-bottom: 10px;
  }
  @include respond-to(sm) {
    padding: 0;
    margin-bottom: 10px;
  }
  .videoBox {
    width: 100%;
    position: relative;
    margin: 0 auto;

    .img {
      width: 100%;
    }

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>